@import "./colors.scss";

.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-16 {
  font-size: 18px;
}
.font-18 {
  font-size: 18px;
}
.font-24 {
  font-size: 24px;
}

.f-100 {
  font-weight: 100;
}
.f-300 {
  font-weight: 300;
}
.f-400 {
  font-weight: 400;
}
.f-600 {
  font-weight: 600;
}
.f-700 {
  font-weight: 700;
}
.f-800 {
  font-weight: 800;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-nowrap {
  white-space: nowrap;
}

.ellipsis {
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  word-break: break-word;
}

.ellipsis-2 {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
